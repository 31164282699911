import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../globals/buttons/Button';
import Tag from '../../../globals/buttons/Tag';
import { isEmpty } from 'lodash';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { OtherLinkDataAttributes } from '../../../constants/gtm';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import { richTextCharaterLimit } from '../../../utils/HelperUtils';

const CardOfferings = ({
  title,
  text,
  ctaText,
  ctaUrl,
  cssClass,
  tags,
  fields,
  serviceProvider,
  deliveredByText = '',
  relatedServicesText = '',
  enquirenow = '',
  componentName = '',
}) => {
  let tagsToShow = [],
    matchedValue = [];
  let objectTag = [],
    final = [],
    final_tags_limit = [];

  let keysItem = fields ? Object.keys(fields) : [];

  const keysToMatch = keysItem && keysItem.map((e) => e.toLowerCase());

  const showtags = () => {
    tags &&
      tags.map((i) => {
        tagsToShow.push(i?.fields?.name?.value.replace(/ /g, '').toLowerCase());
      });

    for (let i = 0; i < tagsToShow.length; i++) {
      if (keysToMatch.includes(tagsToShow[i])) {
        if (tagsToShow[i] === 'lineofbusiness') {
          tagsToShow[i] = 'lineOfBusiness';
        }
      }
    }
    matchedValue.push(tagsToShow);

    tagsToShow.map((e) => {
      if (fields[e] !== undefined) {
        objectTag.push(fields[e]);
      }
      final = objectTag.flat(1);
      final_tags_limit = final.splice(0, 1);
    });
  };
  showtags();

  return (
    <a
      href={ctaUrl}
      className={`card card__offering ${cssClass} card-offerings-solution`}
      {...OtherLinkDataAttributes({
        name: componentName,
        text: text?.value,
        url: ctaUrl,
        dataItemName: title?.value,
      })}
    >
      <div className="card-body">
        <div className="card-content-offering">
          <h3 className="title clamp-2">{<TextSitecoreField field={title} />}</h3>
          <p className="description">
            {<RichTextSitecoreField field={richTextCharaterLimit(text, 300)} />}
          </p>
          {serviceProvider && (
            <div className="service-provider">
              <span>{serviceProvider}</span>
            </div>
          )}
        </div>
        {final_tags_limit && final_tags_limit.length > 0 && (
          <div className="tags">
            <label className="delivered small">{`${deliveredByText}: `}</label>

            {final_tags_limit &&
              final_tags_limit.map(
                (i3) =>
                  i3?.fields &&
                  i3?.fields?.displayInUI && (
                    <label className="delivered small-offering" key={i3?.id}>
                      {' '}
                      {i3?.fields?.title?.value}
                    </label>
                  )
              )}
          </div>
        )}
        {final_tags_limit && final_tags_limit.length > 0 && (
          <div className="tags">
            <label className="related-services small">{`${relatedServicesText}: `}</label>

            {final_tags_limit &&
              final_tags_limit.map(
                (i3) =>
                  i3?.fields &&
                  i3?.fields?.displayInUI && (
                    <Tag
                      key={i3?.id}
                      fontSize="small"
                      text={i3?.fields?.title?.value}
                      icon={i3?.fields?.icon}
                      componentName={componentName}
                      dataItemName={title?.value}
                    />
                  )
              )}
          </div>
        )}

        {enquirenow && (
          <div className="cta__wrap__offering">
            <Button
              variant="text-button"
              fontSize="small"
              text={enquirenow}
              componentName={componentName}
              dataItemName={title}
            />
          </div>
        )}
      </div>
    </a>
  );
};

CardOfferings.defaultProps = {
  title: '',
  text: '',
  ctaText: '',
  ctaUrl: '',
  serviceProvider: '',
  cssClass: '',
  tags: [],
  fields: {},
  cssClass: '',
  deliveredByText: '',
  relatedServicesText: '',
  componentName: '',
};

CardOfferings.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  serviceProvider: PropTypes.string,
  tags: PropTypes.array,
  cssClass: PropTypes.string,
  fields: PropTypes.shape({}),
  deliveredByText: PropTypes.string,
  relatedServicesText: PropTypes.string,
  componentName: PropTypes.string,
};

export default CardOfferings;
